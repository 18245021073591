/* eslint-disable no-param-reassign */
import { UIButton, UIFormattedNumber, UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { showModal } from '../../../../actions'
import messages from '../../../../consts/messages'
import { getSelectedTicketsValues } from '../../../../services/pricing/reducers/selectors'
import { getModifying } from '../../../../services/reservation/selectors'
import { getLocale } from '../../../../services/user-selections'
import IncDecDropDown from '../../../Booking/forms/TicketsForm/IncDecDropDown.connect'
import TicketsFormContainer from '../../../Booking/forms/TicketsForm/TicketsFormContainer'
import moduleStyles from '../../Modals.module.scss'

const AddOrEditAddonModal = (props) => {
  const { editMode, showModal, fetchingModify, locale, selectedTickets } = props
  const addonTickets = selectedTickets.filter(
    (ticket) => ticket.inventoryClass !== 'DECK' && ticket.subType !== 'VEHICLE' && typeof ticket === 'object'
  )
  const currency = addonTickets && addonTickets[0] && addonTickets[0].currency
  const subTotal = addonTickets && addonTickets.reduce((acc, addon) => (acc += addon.price * addon.count), 0)

  return (
    <UILayout width="500px" column data-test="addOrEditPassengerTickets-modal" bgColor="white">
      <UILayout padding="20px 0" borderBottom="1px solid #C1CADE" center className={moduleStyles.direction}>
        <UIText
          font="ADPortsGroupBold, sans-serif"
          color="#2D3955"
          size="18px"
          translate={messages.addOrEditAddonModalTitle}
          className={moduleStyles.text}
        />
      </UILayout>

      <UILayout column padding="20px 0" borderBottom="1px solid #C1CADE">
        {addonTickets &&
          addonTickets.map((addon) => (
            <IncDecDropDown
              key={addon.code}
              icon={addon.subType === 'BICYCLE' ? 'iconBike' : ''}
              title={addon.titles && addon.titles[locale]}
              editMode={editMode}
              isAddon={true}
              addonData={addon}
              {...props}
            />
          ))}
      </UILayout>

      <UILayout center j-flex-center padding="20px 0" borderBottom="1px solid #C1CADE">
        <UIText
          margin="0 10px 0 0"
          font="ADPortsGroupBold, sans-serif"
          color="#2D3955"
          size="16px"
          translate={messages.modalSubtotal}
        />
        <UIText font="ADPortsGroupBold, sans-serif" color="#2D3955" size="16px">
          <UIFormattedNumber style="currency" currency={currency} value={subTotal} />
        </UIText>
      </UILayout>

      <UILayout j-flex-center padding="20px 0">
        <UIButton
          disabled={fetchingModify}
          onClick={() => showModal('')}
          bgColor="#13C5BD"
          borderRadius="6px"
          width="200px"
          height="50px"
        >
          <UILayout center j-flex-center height="100%">
            <UIText
              size="20px"
              font="ADPortsGroupBold, sans-serif"
              textTransform="uppercase"
              color="white"
              translate={messages.doneButtonLabel}
            />
          </UILayout>
        </UIButton>
      </UILayout>
    </UILayout>
  )
}

const mapStateToProps = (state) => {
  const ticketsFormValues = getSelectedTicketsValues(state)
  const selectedTickets = Object.keys(ticketsFormValues)
    .map((key) => ticketsFormValues[key])
    .filter((ticket) => typeof ticket === 'object' && ticket.code !== 'CHARTER')
  const locale = getLocale(state)
  const fetchingModify = getModifying(state)

  return {
    locale,
    fetchingModify,
    selectedTickets,
  }
}

export default compose(connect(mapStateToProps, { showModal }), TicketsFormContainer)(AddOrEditAddonModal)
