import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText, UICheckbox, UIButton } from 'bora-material-ui'

import messages from '../../../consts/messages'
import { olderBrowser } from '../../../utils/browserUtils'

const styles = {
  label: { size: '16px', color: '#000000', margin: '0 0 0 16px', textAlign: 'left' },
  labelIcon: { fill: '#d0021b', margin: 0, textAlign: 'left' },
  button: { width: '100%', margin: '0 0 16px 0' },
  bottom: { column: true, margin: '16px 0 0 0' },
  checkBoxStyle: { margin: '0' },
}

const ModalAgreeFooter = ({ confirmed, removeAction, onClose }, { muiTheme }) => (
  <UILayout j-flex-space-between center margin="40px 0 0 0" xs={styles.bottom}>
    <UIButton
      id="buttonStyle"
      click={onClose}
      {...muiTheme.ids.buttonStyles}
      width="50%"
      height="87px"
      xs={styles.button}
      {...muiTheme.ids.buttonActionStyles}
    >
      <UILayout
        center
        margin="0 24px"
        lineHeight="30px"
        height="100%"
        width="auto"
        style={styles.spaceBtw}
        xs={styles.goToNextTitleBlock}
        data-testid="agree-button-texts"
      >
        <UILayout column width="auto">
          <UIText
            id="buttonLabelTextStyle"
            align="left"
            size={muiTheme.smallerSize}
            opacity="0.5"
            translate={messages.changeMyMind}
          />
          <UIText
            id="buttonTextStyle"
            align="left"
            textTransform="uppercase"
            size={olderBrowser ? '20px' : muiTheme.fontSizeBigger}
            translate={messages.cancelOparation}
          />
        </UILayout>
      </UILayout>
    </UIButton>

    <UILayout center width="50%" j-flex-center>
      <UIText
        color={confirmed ? '#d0021b' : 'grey'}
        size="16px"
        textDecoration="underline"
        cursor={confirmed ? 'pointer' : 'none'}
        translate={messages.removeUser}
        onClick={removeAction}
      />
    </UILayout>
  </UILayout>
)

ModalAgreeFooter.propTypes = {
  confirmed: PropTypes.func.isRequired,
  removeAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

ModalAgreeFooter.contextTypes = {
  muiTheme: PropTypes.object,
  intl: PropTypes.any,
}

class ModalAgree extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      confirmed: false,
    }
  }

  render() {
    const { removeAction, confirmText, onClose } = this.props

    return (
      <UILayout
        data-test="modal-confirm "
        padding="0 0 20px 0"
        width="580px"
        maxWidth="100%"
        bgColor="#fff6d8"
        j-flex-center
        column
      >
        <UILayout column margin="16px 0">
          {this.props.points}
          <UICheckbox
            labelStyle={styles.label}
            onCheck={() => this.setState({ confirmed: !this.state.confirmed })}
            label={confirmText}
            iconStyle={styles.labelIcon}
            style={styles.checkBoxStyle}
          />
        </UILayout>

        <ModalAgreeFooter removeAction={removeAction} confirmed={this.state.confirmed} onClose={onClose} />
      </UILayout>
    )
  }
}

ModalAgree.defaultProps = {
  points: null,
}

ModalAgree.propTypes = {
  removeAction: PropTypes.func.isRequired,
  points: PropTypes.any,
  confirmText: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
}

ModalAgree.contextTypes = {
  muiTheme: PropTypes.object,
  intl: PropTypes.any,
}

export default ModalAgree
