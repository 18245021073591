import { UIButton, UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import messages from '../../../../consts/messages'
import { translate } from '../../../Common/Translator'
import MaritimeOptionContainer from '../../../Booking/containers/MaritimeOptions/MaritimeOption.connect'

class BuyAllPassengerTicketsModalContent extends React.Component {
  render() {
    const {
      isRoundTrip,
      thereLegTitle,
      backLegTitle,
      availableSeatsAmountThere,
      availableSeatsAmountBack,
      amountOfSeats,
      categoriesAvailableForSelection,
      changeItemQtty,
      showModal,
    } = this.props

    const addAvailableTickets = () => {
      const charterTicket = categoriesAvailableForSelection.find((category) => category.code === 'CHARTER_PAX') || {}
      changeItemQtty({
        ...charterTicket,
        count: availableSeatsAmountThere,
        countThere: availableSeatsAmountThere,
        countBack: availableSeatsAmountBack,
        roundTrip: isRoundTrip,
        charter: true,
        type: 'dropdown',
      })
      showModal('')
    }

    return (
      <UILayout width="500px" column data-test="addOrEditPassengerTickets-modal" bgColor="white" padding="20px">
        <UILayout margin="0 0 20px 0">
          <UIText
            display-if={!isRoundTrip}
            color="#2D3955"
            size="18px"
            formattedMessage={translate(messages.buyAllPassengerTicketsOneWayModalText, {
              availableSeatsAmount: availableSeatsAmountThere,
              amountOfSeats,
              legTitle: thereLegTitle,
            })}
          />
          <UIText
            display-if={isRoundTrip}
            color="#2D3955"
            size="18px"
            formattedMessage={translate(messages.buyAllPassengerTicketsRoundTripModalText, {
              availableSeatsAmountThere,
              availableSeatsAmountBack,
              amountOfSeats,
              thereLegTitle,
              backLegTitle,
            })}
          />
        </UILayout>
        <UILayout j-flex-center>
          <UIButton width="160px" height="50px" borderRadius="6px" click={addAvailableTickets}>
            <UILayout height="100%" center j-flex-center>
              <UIText
                font="ADPortsGroupBold, sans-serif"
                textTransform="uppercase"
                size="18px"
                translate={messages.ok}
              />
            </UILayout>
          </UIButton>
        </UILayout>
      </UILayout>
    )
  }
}

export default MaritimeOptionContainer(BuyAllPassengerTicketsModalContent)
