import React from 'react'
import PropTypes from 'prop-types'
import moduleStyles from '../../../Modal/Modals.module.scss'

import { UILayout } from 'bora-material-ui'

const getChildStyles = (index, sideBorder, gap, xsChildStyles, childrenAmount) => {
  const right = index === childrenAmount - 1 ? { paddingInlineEnd: '0' } : {}
  const left = index === 0 ? { paddingInlineStart: '0' } : {}

  return {
    rowChild: {
      borderInlineStart: index === 0 ? 0 : sideBorder,
      padding: `0 ${gap}`,
      ...left,
      ...right,
    },
    xs: {
      rowChild: {
        width: '100%',
        style: {
          border: '0',
          borderTop: index === 0 ? 0 : sideBorder,
          padding: `${gap} 0`,
          paddingTop: index === 0 ? 0 : gap,
        },
        ...xsChildStyles,
      },
    },
  }
}

const getRowStyles = (border) => ({
  rowContainer: { borderBottom: border },
  xs: {
    rowContainer: { column: true, margin: '0' },
  },
})

const RowContainer = (props) => {
  const { children, sideBorder, childAdStyles, rowAdStyles, xsChildStyles, rowBorderBottom, coef, indent } = props

  const childrenArrayNotFiltered = Array.isArray(children) ? children : [children]

  const childrenArray = childrenArrayNotFiltered.filter((child) => child)

  const childrenAmount = childrenArray.length
  const childrenWidth = children ? 100 / childrenAmount : 0

  const rowStyles = getRowStyles(rowBorderBottom)

  const gap = `${Number(coef) * Number(indent)}px`

  return (
    <UILayout
      data-test="row-container"
      display-if={children}
      j-flex-space-between
      maxWidth="100%"
      margin={`0 ${gap}`}
      padding={`${gap} 0`}
      style={rowStyles.rowContainer}
      sm={rowStyles.xs.rowContainer}
      {...rowAdStyles}
      className={moduleStyles.formDirection}
    >
      {childrenArray.map((child, index) => {
        const styles = getChildStyles(index, sideBorder, gap, xsChildStyles, childrenAmount)
        return (
          <UILayout
            data-testid={`row-child-${index}`}
            shrink="0"
            width={`${childrenWidth}%`}
            style={styles.rowChild}
            sm={styles.xs.rowChild}
            {...childAdStyles}
          >
            {child}
          </UILayout>
        )
      })}
    </UILayout>
  )
}

RowContainer.propTypes = {
  children: PropTypes.any,
  sideBorder: PropTypes.string,
  childAdStyles: PropTypes.object,
  rowAdStyles: PropTypes.object,
  xsChildStyles: PropTypes.object,
  rowBorderBottom: PropTypes.string,
  coef: PropTypes.any,
  indent: PropTypes.any,
}

RowContainer.defaultProps = {
  children: false,
  sideBorder: '1px solid #bfc3c7',
  childAdStyles: {},
  rowAdStyles: {},
  xsChildStyles: {},
  rowBorderBottom: '1px solid #bfc3c7',
  coef: 8,
  indent: 2,
}

export default RowContainer
